import React from "react"

import Layout from "../../components/layout"

import {
  CardBenalcons,
  CardEmabesa,
} from "../../components/sections/projects/cards"
import { LocalizedLink } from "gatsby-theme-i18n"

const ProjectsPage = () => {
  return (
    <Layout>
      <div className="my-8 lg:my-0 w-full flex flex-col gap-8 lg:flex-row items-center justify-evenly min-h-screen lg:pt-12 lg:-mt-32 lg:-mb-32 px-4">
        <LocalizedLink to="/projects/projectsBenalcons">
          <CardBenalcons />
        </LocalizedLink>
        <LocalizedLink to="/projects/projectsEmabesa">
          <CardEmabesa />
        </LocalizedLink>
      </div>
    </Layout>
  )
}

export default ProjectsPage
