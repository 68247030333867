import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

//cards indexPage project
export const CardBenalcons = () => {
  const { t } = useTranslation("projects")

  return (
    <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
      <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
        <StaticImage
          height={430}
          width={640}
          src="../../../images/card-benalcons.png"
          alt="Impermeabilización de techos y tejados."
          placeholder="tracedSVG"
          className="rounded-t-md shadow-md "
        />
        <div className="flex items-center justify-start py-2 w-full">
          <StaticImage
            height={59}
            width={60}
            src="../../../images/obrero.png"
            alt="Impermeabilización de techos y tejados."
            placeholder="tracedSVG"
            className="rounded-md ml-2 flex-shrink-0"
          />
          <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
            {t("indexBenalcons")}
          </h3>
        </div>
      </div>
    </div>
  )
}

export const CardEmabesa = () => {
  const { t } = useTranslation("projects")

  return (
    <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
      <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
        <StaticImage
          height={430}
          width={640}
          src="../../../images/card-emabesa.png"
          alt="Impermeabilización de techos y tejados."
          placeholder="tracedSVG"
          className="rounded-t-md shadow-md "
        />
        <div className="flex items-center justify-start py-2 w-full">
          <StaticImage
            height={59}
            width={60}
            src="../../../images/obrero.png"
            alt="Impermeabilización de techos y tejados."
            placeholder="tracedSVG"
            className="rounded-md ml-2 flex-shrink-0"
          />
          <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
            {t("indexEmabesa")}
          </h3>
        </div>
      </div>
    </div>
  )
}

//cards section projects
export const CardBenalconsProjects = ({ title, children }) => {
  return (
    <div className="flex items-center justify-center p-4 lg:p-8">
      <div className="flex  relative max-w-2xl lg:px-0 ">
        {children}
        <div className="flex flex-col">
          <StaticImage
            height={200}
            src="../../../images/logo.png"
            alt="logo"
            placeholder="tracedSVG"
            className="transform scale-50 translate-y-8 opacity-30 lg:opacity-80"
          />
          <h2 className="px-1 text-center font-montserrat-semi break-words">
            {title}
          </h2>
        </div>
      </div>
    </div>
  )
}

export const CardEmabesaProjects = ({ title, children }) => (
  <div className="flex items-center justify-center h-3/4 p-4 lg:p-8 ">
    <div className="flex relative max-w-2xl lg:px-0 ">
      {children}
      <div className="rounded-md shadow-md absolute bottom-0 right-0 left-0 flex flex-col items-center justify-end bg-gradient-to-t from-orange-light via-orange-light to-transparent opacity-70 lg:opacity-80"></div>
      <StaticImage
        height={200}
        src="../../../images/emabesa.png"
        alt="logo"
        placeholder="tracedSVG"
        className="transform scale-50 translate-y-8 opacity-30 lg:opacity-80"
      />
      <h2 className="px-1 text-center font-montserrat-semi break-words mb-8">
        {title}
      </h2>
    </div>
  </div>
)
